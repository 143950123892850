$font1: 'Adieu';
$font2: 'Optima';
$vw-viewport-m: 375;
$vw-viewport-d: 1150;
$html-font-size: 16px;
$vw-enable: rem;

@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}

@function get-vw-m($target){
  @if $vw-enable == true {
    $vw-context: $vw-viewport-m * 0.01 * 1px;
    @return $target / $vw-context * 1vw;
  } @else if $vw-enable == rem {
    @return #{stripUnit($target) / stripUnit($html-font-size)}rem;
  }
  @return $target
};

@function get-vw-d($target){
  @if $vw-enable {
    $vw-context: $vw-viewport-d * 0.01 * 1px;
    @return $target / $vw-context * 1vw;
  } @else if $vw-enable == rem {
    @return #{stripUnit($target) / stripUnit($html-font-size)}rem;
  }
  @return $target
}
  
  

    

  