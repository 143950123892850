h1, h2, footer {
    font-family: $font1;
    text-transform: uppercase;
}

p, main li, header li {
    font-family: $font2;
}

a {
    color: #000;
    text-decoration: none;
}

ul {
    display: flex;
}