main {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    h2 {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        font-size: get-vw-d(28px);
    }

    li, #image2, #image3, #image4 {
        font-size: get-vw-d(12px);
        line-height: get-vw-d(14.4px);
        text-align: center;
    }

    #tagline1, #tagline2, #tagline3 {
        font-size: get-vw-d(28px);
        line-height: get-vw-d(34px);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#intro {
    grid-area: span 2 / span 4;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: get-vw-d(40px);
        line-height: get-vw-d(50px);
        text-align: center;
        margin-top: get-vw-d(64px);
    }

    #team {
        margin-top: get-vw-d(58px);
        font-size: get-vw-d(12px);
        line-height: get-vw-d(14.4px);

        li {
            margin-left: get-vw-d(9px);
        }
    }
}

#image1, #title1, #image2, #image3, #image5, #title3 {
    grid-area: span 1 / span 1;
}

#title2, #tagline1, #tagline3 {
    grid-area: span 2 / span 1;
}

#tagline2, #image6, #image7 {
    grid-area: span 1 / span 2;
}

#image4 {
    grid-area: span 2 / span 2;
}

.manifest-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

#intro, #title2, #tagline1, #image2, #image3, #image5, #title3, #tagline3, #image6  {
    border-right: 1px solid #979797;
}

#intro, #image1, #title1, #title2, #tagline1, #tagline2, #image2, #image3, #image4, #image5, #title3, #tagline3, #image6, #image7  {
    border-bottom: 1px solid #979797;
}

#image1 {
    padding-top: get-vw-d(28px);
    padding-left: get-vw-d(25px);
    padding-right: get-vw-d(20px);
    padding-bottom: get-vw-d(25px);
}

#title1 {
    padding-top: get-vw-d(29px);
    padding-bottom: get-vw-d(37px);
}

#image2, #image3 {
    padding-top: get-vw-d(33px);
    padding-left:  get-vw-d(23px);
    padding-right:  get-vw-d(23px);

    p {
        padding-top: get-vw-d(2px);
        padding-bottom: get-vw-d(10px);
    }
}

#image4 {
    padding-left:  get-vw-d(46px);
    padding-right:  get-vw-d(53px);

    img {
        margin-top: get-vw-d(52px);
    }

    p {
        padding-top: get-vw-d(45px);
        padding-bottom: get-vw-d(26.6px);
    }
}

#image5 {
    padding-top: get-vw-d(31px);
    padding-bottom: get-vw-d(32px);
    padding-left: get-vw-d(17px);
    padding-right: get-vw-d(15px);
}

#image6, #image7 {
    padding-top: get-vw-d(15px); 
    padding-bottom: get-vw-d(17px);  
    padding-left: get-vw-d(69px);  
    padding-right: get-vw-d(79px);  
}

#image7 {
    padding-right: get-vw-d(76px);  
}