footer {
    display: flex;
    justify-content: flex-end;
    font-size: get-vw-d(12px);
    line-height:  get-vw-d(14.4px);
    padding-top: get-vw-d(27px);
    padding-bottom: get-vw-d(25.6px);

    nav {
        display: flex;
        padding-right: get-vw-d(26px);
    }

	.contact li {
		margin-left: get-vw-d(18px) 
	}
}

.lang {
    margin-right: get-vw-d(55px);
    li {
        margin-left: get-vw-d(18px);
    }
    li:first-of-type {
        opacity: 0.2;
    }
}
