header {
    border-bottom: 1px solid #979797;
    display: flex;
    justify-content: space-between;
    height: get-vw-d(107px);
}

#logo {
    width: get-vw-d(180px);
    margin-top: get-vw-d(37px);
    margin-left: get-vw-d(38px);
    img {
        width: 100%;
    }
}

header nav {
    margin-top: get-vw-d(43px);
    margin-right: get-vw-d(40px);

    li {
        margin-left: get-vw-d(44px);
        font-size: get-vw-d(16px);
        line-height: get-vw-d(19.2px);
        text-transform: capitalize;
    }
}